<template>
  <div>
    <van-nav-bar
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
        title="放映报告审批详情"
    />
    <div class="container">
      <van-cell title="影片名称">
        <template #label>
          <div>
            {{ showReportData.films.map((film) => film.name).join('/') }}
            <!-- <van-cell v-for="(film, index) in showReportData.films" :key="index">
              <template #default>
                <div class="film">
                  <div class="film-mark">{{ index + 1 }}</div>
                  <div class="film-title">{{ film.name }}</div>
                </div>
              </template>
            </van-cell> -->
          </div>
        </template>
      </van-cell>
      <van-cell title="影片类型">
        <template #label>
          <div>
            {{ showReportData.films.map((film) => film.type).join('/') }}
          </div>
        </template>
      </van-cell>
      <van-cell :label="showReportData.playDate" title="放映日期">
        <template #label>
          {{ showReportData.playDate | formatDate }}
        </template>
      </van-cell>
      <van-cell
          arrow-direction="down"
          is-link
          center
          @click="showStartTimeCalendar = true"
          v-if="editStatus"
      >
        <template #label>
          {{ showReportData.playStartTime.slice(0, 5) }}
        </template>
        <template #title>
          <span style="color: red">*</span>放映开始时间
        </template>
      </van-cell>
      <van-cell center v-else>
        <template #label>
          {{ showReportData.playStartTime.slice(0, 5) }}
        </template>
        <template #title>
          <span style="color: red">*</span>放映开始时间
        </template>
      </van-cell>
      <van-cell
          arrow-direction="down"
          is-link
          center
          @click="showEndTimeCalendar = true"
          v-if="editStatus"
      >
        <template #label>
          {{ showReportData.playEndTime.slice(0, 5) }}
        </template>
        <template #title>
          <span style="color: red">*</span>放映结束时间
        </template>
      </van-cell>
      <van-cell center v-else>
        <template #label>
          {{ showReportData.playEndTime.slice(0, 5) }}
        </template>
        <template #title>
          <span style="color: red">*</span>放映结束时间
        </template>
      </van-cell>

      <van-cell title="现场照片" center>
        <template #title><span style="color: red">*</span>现场照片</template>
        <template #label>
          <div class="film-img">
            <van-image
                v-for="(item, index) in showReportData.pictures"
                @click="previewImg"
                :key="index"
                :src="item"
                style="width: 43vw; margin: 5px; border-radius: 5px"
                fit="contain"
            >
              <template #error>加载失败</template>
            </van-image>
          </div>
        </template>
      </van-cell>
      <van-cell title="观众人数" readonly>
        <template #label>
          {{ showReportData.attendanceNum | attendanceNum }}
        </template>
      </van-cell>
      <van-cell title="放映点" readonly>
        <template #label>
          <van-icon name="location"/>
          {{ showReportData.showPoint?.address }}({{
            showReportData.showPoint?.name
          }})
        </template>
      </van-cell>
      <!-- 场地类型 -->
      <van-cell title="放映场地" readonly>
        <template #label>
          {{ showReportData.playPlace | playPlaceType }}
        </template>
      </van-cell>
      <!-- 打卡信息 -->
      <van-cell title="打卡点信息" readonly>
        <template #label>
          <div>
            <van-icon name="location"/>
            {{
              showReportData.remark?.detailAddress
            }}
          </div>
<!--          <div>-->
<!--            <van-icon name="aim"/>-->
<!--            经度：{{-->
<!--              showReportData.remark?.longitude.toFixed(5)-->
<!--            }}-->
<!--            / 纬度：{{ showReportData.remark?.latitude.toFixed(5) }}-->
<!--          </div>-->
        </template>
      </van-cell>
      <!--  放映解码卡号 -->
      <van-cell
          readonly
          is-link
          center
          arrow-direction="down"
          @click="$refs.cinemaCompanyCardCodePicker.show()"
          v-if="editStatus"
      >
        <template #title>
          <span style="color: red">*</span> 放映解码卡号
        </template>
        <template #label>
          <!-- <van-field type="text" label="" placeholder="请选择设备编码" v-model="showReportData.cinemaCompanyCardCode"/> -->
          {{ showReportData.cinemaCompanyCardCode }}
        </template>
      </van-cell>
      <!-- 放映员 -->
      <van-cell title="放映员" readonly>
        <template #label>
          <van-icon name="contact-o"/>
          {{ showReportData.filmTeam?.realname }}
        </template>
      </van-cell>

      <div class="container-title">放映设备数据</div>
      <van-cell
          title=" 放映解码卡号"
          readonly
          :label="showReportData.cinemaCompanyCardCode"
      />
<!--      <van-cell title="放映设备定位" readonly>-->
<!--        <template #label>-->
<!--          <div>-->
<!--            <van-icon name="aim"/>-->
<!--            经度：{{-->
<!--              getLastItem(showReportData.filmLogs)?.lng | meterNum-->
<!--            }}-->
<!--            / 纬度：{{ getLastItem(showReportData.filmLogs)?.lat | meterNum }}-->
<!--          </div>-->
<!--        </template>-->
<!--      </van-cell>-->

      <div class="container-title">{{ resultTitle }}</div>
      <van-cell
          title="放映设备状态"
          readonly
          :label="getLastItem(showReportData.filmLogs)?.status"
      >
        <template #title>
          <van-icon
              name="checked"
              :color="
              isPointFilmLogPass(showReportData.showPoint)
                ? '#67c23a'
                : '#fb9308'
            "
          />
          定位距离
        </template>
        <template #label>
          距离放映点：{{ showReportData.showPoint|pointDistance }}
<!--          /-->
<!--          距离放映设备：-->
<!--          {{ showReportData.filmLogs|deviceDistance }}-->
        </template>
      </van-cell>
      <van-cell
          title="放映设备状态"
          readonly
          :label="getLastItem(showReportData.filmLogs)?.meter"
      >
        <template #title>
          <van-icon
              name="checked"
              :color="showReportData.filmLogs.length === getEffectiveFilmNum(showReportData.filmLogs)
              &&showReportData.filmLogs.length===showReportData.films.length
                ? '#67c23a'
                : '#fb9308'
            "
          />
          放映影片
        </template>
        <template #label>
          <van-cell-group>
            <van-cell>
              <template #title>
                <span style="color: #439109">有效放映影片</span>
              </template>
              <template #default>
                <!--                {{-->
                <!--                  showReportData.filmLogs?.filter((item) => item.effective)-->
                <!--                      .length-->
                <!--                }}-->
                {{ getEffectiveFilmNum(showReportData.filmLogs) }}
              </template>
            </van-cell>
            <van-cell>
              <template #title>
                <span style="color: #fb9308">无效放映影片</span>
              </template>
              <template #default>
                {{
                  showReportData.filmLogs?.filter((item) => !item.effective)
                      .length
                }}
              </template>
            </van-cell>
          </van-cell-group>
        </template>
      </van-cell>
      <van-cell title="放映记录">
        <template #label>
          <div class="film-table">
            <div class="film-table-head">
              <div>影片名称</div>
              <div>放映时间</div>
              <div>影片类型</div>
              <div v-if="editStatus">操作</div>
            </div>
            <!-- 删除样式 -->
            <div
                class="film-table-row"
                v-for="(item, index) in showReportData.filmLogs"
                :key="index"
                :class="{ deleted: excludeFilms.includes(item.filmLogId) }"
            >
              <div>{{ item.filmName }}</div>
              <div>
                {{ item.playStartTime | formatTime }} -
                {{ item.playEndTime | formatTime }}
              </div>
              <div>{{ item.filmstyle }}</div>
              <div v-if="editStatus">
                <van-icon
                    name="delete"
                    color="#f5222d"
                    size="20"
                    @click="excludeFilms.push(item.filmLogId)"
                    v-if="!excludeFilms.includes(item.filmLogId)"
                />
                <van-icon
                    name="add-square"
                    color="#67c23a"
                    size="20"
                    v-else
                    @click="
                    excludeFilms.splice(excludeFilms.indexOf(item.filmLogId), 1)
                  "
                />
              </div>
            </div>
            <div style="text-align: center" v-if="editStatus">
              <van-button
                  type="info"
                  plain
                  size="mini"
                  @click="excludeFilms = []"
                  link
                  icon="replay"
              >数据重载
              </van-button
              >
            </div>
            <!-- {{ showReportData.filmLogs }} -->
          </div>
        </template>
      </van-cell>
      <van-cell title="审核结果" v-if="showReportData.status === 2">
        <template #label>
          <div
              v-for="(item, index) in showReportData.audit"
              :key="index"
              class="audit-item"
          >
            <div class="audit-item-left">
              <span style="font-weight: bold; color: #000">{{
                  item.level | levelRole
                }}</span>
              <van-image
                  :src="item.auditSignature"
                  style="width: 40vw; margin: 5vw"
                  v-if="[3, 4].includes(item.auditResult)"
              />
            </div>
            <div
                class="audit-item-right"
                :class="'audit-item-right-status-' + item.auditResult"
                v-if="item.auditResult === 0"
            >
              待处理
            </div>
            <div
                class="audit-item-right"
                :class="'audit-item-right-status-' + item.auditResult"
                v-else-if="item.auditResult === 1"
            >
              待审核
            </div>
            <div
                class="audit-item-right"
                :class="'audit-item-right-status-' + item.auditResult"
                v-else-if="item.auditResult === 2"
            >
              审核中
            </div>
            <div
                class="audit-item-right"
                :class="'audit-item-right-status-' + item.auditResult"
                v-else-if="item.auditResult === 3"
            >
              {{ item.operateTime | formatDate }}
            </div>
            <div
                class="audit-item-right"
                :class="'audit-item-right-status-' + item.auditResult"
                v-else-if="item.auditResult === 4"
            >
              审核不通过
            </div>
          </div>
        </template>
      </van-cell>
      <van-cell>
        <template #title><span style="color: red">*</span> 提示信息</template>
        <template #label>
          <pre
              style="
              font-size: 14px;
              color: #686b73;
              font-family: PingFangSC, PingFang SC;
              margin-bottom: 10vh;
            "
          >
    1.如发现放映设备场次同步信息缺少，可能是：
      a.放映设备数据延迟，请等待数据更新后再确认；
      b.检查放映开始时间及放映结束时间填写是否有误；

    2.请确认影片数据无误，再提交审核，一旦提交将不能
      回退。
          </pre>
        </template>
      </van-cell>

      <pre v-isDev>
        <pre>
          {{ this.showReportData }}
        </pre>
      </pre>
      <div class="container-btn">
        <!-- 尾部按钮 保存、提交审核 -->
        <!-- <span v-if="[2].includes(showReportData.status)">审核中</span> -->
        <van-button
            type="info"
            size="small"
            plain
            block
            v-if="[2].includes(showReportData.status)"
        >
          审核中...
        </van-button>

        <van-button
            type="info"
            size="small"
            round
            plain
            block
            @click="handleSave"
            v-if="![2].includes(showReportData.status)"
        >保存
        </van-button
        >
        <van-button
            type="info"
            size="small"
            round
            block
            @click="handleSubmit"
            v-if="![2].includes(showReportData.status)"
        >提交审核
        </van-button
        >
      </div>
    </div>
    <!-- 开始时间选择器弹窗 -->
    <van-popup
        v-model="showStartTimeCalendar"
        position="bottom"
        :style="{ height: '40%' }"
    >
      <van-datetime-picker
          type="time"
          @confirm="onConfirmStartTime"
          @cancel="showStartTimeCalendar = false"
          title="选择放映开始时间"
      />
    </van-popup>
    <!-- // 结束时间选择器弹窗 -->
    <van-popup
        v-model="showEndTimeCalendar"
        position="bottom"
        :style="{ height: '40%' }"
    >
      <van-datetime-picker
          type="time"
          @confirm="onConfirmEndTime"
          @cancel="showEndTimeCalendar = false"
          title="选择放映结束时间"
      />
    </van-popup>
    <!--  放映解码卡号选择器弹窗 -->
    <!-- // 放映设备选择器弹窗 -->
    <cinema-company-card-code-picker
        ref="cinemaCompanyCardCodePicker"
        @confirm="confirmEquipment"
    >
    </cinema-company-card-code-picker>
    <!-- <status-picker ref="statusPicker" :data="showReportData"></status-picker> -->
  </div>
</template>

<script>
import {api} from '@/api/index'
import {Dialog, ImagePreview, Toast} from 'vant'
import config from '@/config'
import cinemaCompanyCardCodePicker from '@/components/cinemaCompanyCardPicker/index.vue'
// import statusPicker from './statusPicker.vue'
const levelRole = {
  1: '省管理员',
  2: '市管理员',
  3: '县管理员',
  4: '乡镇管理员',
  5: '乡镇/街代表',
  6: '集体代表',
}

export default {
  components: {
    cinemaCompanyCardCodePicker,
    // statusPicker,
  },
  data() {
    return {
      // 编辑状态
      editStatus: true,
      showEndTimeCalendar: false, // 结束时间选择器弹窗
      showStartTimeCalendar: false, // 开始时间选择器弹窗
      showReportId: '',
      // 距离是否通过验证
      isDistancePass: true,
      showReportData: {
        films: [],
        showPoint: {meter: 0},
        filmLogs: [],
        status: '',
        attendanceNum: 0,
        playDate: '',
        playStartTime: '',
        playEndTime: '',
        pictures: [],
      },
      // 审核结果标题
      resultTitle: '审核结果',
      // 查询设备放映记录条件
      query: {
        // 设备id
        deviceId: '',
      },
      // 排除放映记录
      excludeFilms: [],
    }
  },
  created() {
    // console.log(this.$route.params.id);
    this.showReportId = this.$route.params.id
  },
  mounted() {
    // this.$refs.statusPicker.show()
    // console.log(this.showReportId);
    this.getShowReportData()
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1)
    },
    getShowReportData() {
      // console.log( api.filmmaker);
      api.filmmaker.getReportDetail({id: this.showReportId}).then((res) => {
        const {success, result, message} = res
        if (success) {
          this.showReportData = {
            ...result,
            remark: JSON.parse(result.remark),
            filmLogs: !result.filmLogs ? [] : result.filmLogs,
          }
          console.log(this.showReportData)
          if (this.showReportData.status === 2) {
            this.editStatus = false
          }
          setTimeout(() => {
            this.isWatch = true // 监听数据变化
            Toast.success('数据加载成功')
          }, 200)
        } else {
          Toast(message)
        }
      })
    },
    previewImg() {
      console.log('预览图片', this.showReportData)
      ImagePreview([...this.showReportData.pictures])
    },
    // 取数组最后一条数据
    getLastItem(arr) {
      return arr[arr.length - 1]; // 返回数组最后一条数据
    },
    // 保存
    handleSave() {
      console.log(this.showReportData)
      const outFlogIds = this.showReportData.filmLogs
          .filter((item) => !this.excludeFilms.includes(item.filmLogId))
          .map((item) => item.filmLogId)
      const putData = {
        id: this.showReportId,
        cinemaCompanyCardCode: this.showReportData.cinemaCompanyCardCode,
        outFlogIds: outFlogIds, // 修正为 outFlogIds
        playEndTime: this.showReportData.playEndTime, // 根据需要动态生成
        playStartTime: this.showReportData.playStartTime, // 根据需要动态生成
      }
      api.filmmaker
          .updateReport(putData)
          .then((res) => {
            console.log({res})
            const {success, message} = res
            if (success) {
              Toast('保存成功')
              this.onClickLeft()
            } else {
              Toast(message)
            }
          })
          .catch((error) => {
            console.error('更新报告失败:', error)
          })
    },
    // 提交审核
    handleSubmit() {
      const outFlogIds = this.showReportData.filmLogs
          .filter((item) => !this.excludeFilms.includes(item.filmLogId))
          .map((item) => item.filmLogId)
      console.log(this.showReportData)
      const putData = {
        id: this.showReportId,
        cinemaCompanyCardCode: this.showReportData.cinemaCompanyCardCode,
        outFlogIds: outFlogIds, // 修正为 outFlogIds
        playEndTime:  this.showReportData.playEndTime, // 根据需要动态生成
        playStartTime: this.showReportData.playStartTime, // 根据需要动态生成
      }
      console.log({putData})

      Dialog.confirm({
        title: '提交审核',
        message: '确定提交审核吗？',
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        callback: (action) => {
          if (action === 'confirm') {
            api.filmmaker
                .submitReport(putData)
                .then((res) => {
                  console.log({res})
                  const {success, message} = res
                  if (success) {
                    Dialog.alert({
                      title: '提示',
                      message: '提交成功',
                    }).then(() => {
                      this.onClickLeft()
                    })
                  } else {
                    Dialog.alert({
                      title: '提示',
                      message: message,
                    })
                  }
                })
                .catch((error) => {
                  console.error('提交审核失败:', error)
                })
          }
        },
      })
    },
    // 选择结束时间
    onConfirmEndTime(time) {
      if (time < this.showReportData.playStartTime) {
        Toast('结束时间不能早于开始时间')
        return
      }
      this.showEndTimeCalendar = false
      this.showReportData.playEndTime = time+':00'
    },
    // 选择开始时间
    onConfirmStartTime(time) {
      if (time > this.showReportData.playEndTime) {
        Toast('开始时间不能晚于结束时间')
        return
      }
      this.showStartTimeCalendar = false
      this.showReportData.playStartTime = time+':00'
    },
    confirmEquipment(value) {
      console.log('选择的设备编码', value)
      this.showReportData.cinemaCompanyCardCode = value
    },
    // 获取放映员放映记录
    getFilmLogs() {
      const query = {
        playDate: this.showReportData.playDate,
        showReportId: this.showReportId,
        cinemaCompanyCardCode: this.showReportData.cinemaCompanyCardCode,
        playStartTime: this.showReportData.playStartTime
            .slice(0, 5)
            .concat(':00'),
        playEndTime: this.showReportData.playEndTime.slice(0, 5).concat(':00'),
      }
      this.excludeFilms = [] // 重置排除记录
      api.filmmaker.getFilmLogsRecordList(query).then((res) => {
        const {success, result, message} = res
        if (success) {
          this.showReportData.filmLogs =
              result.length > 0
                  ? result
                  : // TODO 假设没有数据，这里应该有个提示
                  []
        } else {
          Toast(message)
        }
      })
    },
    // 打卡与放映机是否通过验证
    isPointFilmLogPass(showPoint) {
      let pass0 = false
      let pass1 = false
      pass0 = showPoint.meter < 1500;
      return pass0 ;
    },
    // 有效影片数去排除影片
    getEffectiveFilmNum(filmLogs) {
      const filmList = filmLogs.filter((item) => item.effective)
      // this.excludeFilms 不在 filmList
      const excludeList = filmList.filter(film =>
          !this.excludeFilms.some(id => id === film.filmLogId)
      );

      console.log({excludeList})
      return excludeList.length
    },
  },
  filters: {
    formatDate(value) {
      const date = new Date(value)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      return `${year}年${month}月${day}日`
    },

    formatTime(value) {
      const date = new Date(value)
      const hour = date.getHours()
      const minute = date.getMinutes().toString().padStart(2, '0')
      const second = date.getSeconds()
      return `${hour}:${minute}`
    },
    attendanceNum(value) {
      const item = config.audienceRange.find((item) => item.value === value)
      // console.log({ item })
      return item?.text || ''
    },
    // 经纬度长度格式化
    meterNum(value) {
      return value?.toFixed(6)
    },
    levelRole(value) {
      return levelRole[value] || ''
    },
    // 场地类型
    playPlaceType(value) {
      const item = config.playPlace.find((item) => item.value === parseInt(value))
      return item?.text || ''
    },
    // 打卡距离放映点
    pointDistance(showPoint) {
      if (showPoint?.meter) {
        // 小于1.5km 显示<1.5km,否侧显示距离km数
        if (showPoint.meter < 1500) {
          return '<1.5km'
        } else {
          return `${(showPoint.meter / 1000).toFixed(2)}km`
        }
      } else {
        // 打卡放映点距离错误
        return '距离错误'
      }
    },
    // 放映设备记录距离放映点
    deviceDistance(filmLogs) {
      if (filmLogs.length > 0) {
        const lastLog = filmLogs[filmLogs.length - 1]
        console.log({lastLog})
        //返回最后一条
        const {meter} = lastLog
        // 小于1.5km 显示<1.5km,否侧显示距离km数
        if (meter < 1500) {
          if (meter > 0) {
            return '<1.5km'
          } else {
            return '放映机无定位'
          }
        } else {
          return `${(meter / 1000).toFixed(2)}km`
        }
      } else {
        return '距离错误'
      }
    }
  },
  watch: {
    'showReportData.playStartTime': {
      handler(value) {
        if (!this.isWatch) return
        this.resultTitle = '预审结果' // 重置审核结果标题
        this.getFilmLogs() // 假设这是一个方法，必要时执行操作
      },
      deep: true, // 开启深度监听，视需要而定
    },
    'showReportData.playEndTime': {
      handler(value) {
        if (!this.isWatch) return
        this.resultTitle = '预审结果' // 重置审核结果标题
        this.getFilmLogs() // 同样的操作
      },
      deep: true, // 开启深度监听，视需要而定
    },
    'showReportData.cinemaCompanyCardCode': {
      handler(value) {
        if (!this.isWatch) return
        this.resultTitle = '预审结果' // 重置审核结果标题
        this.getFilmLogs() // 同样的操作
      },
      deep: true, // 开启深度监听，视需要而定
    },
  },
}
</script>

<style scoped lang="less">
::v-deep .van-cell{
  font-size: 16px;
}
::v-deep .van-cell__title{
  font-size: 18px;
}
::v-deep .van-cell__value{
  font-size: 18px;
}
::v-deep .van-cell__label{
  font-size: 14px;
}
::v-deep .van-cell__value{
  font-size: 18px;
}
.container {
  &-title {
    padding: 10px;
  }

  &-btn {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;

    & > button {
      width: 40vw;
    }
  }
}

/* 可根据需要添加样式 */
.film {
  display: flex;
  align-items: center;

  &-mark {
    display: flex;
    justify-content: center;
    align-items: center;
    color: @color-primary;
    margin: 12px;
    position: relative;

    &:before {
      content: '';
      border: 1px solid @color-primary;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      position: absolute;
    }
  }

  &-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &-title {
    margin-left: 5vw;
    padding: 10px;
    background: #fff;
    border-radius: 4px;
    width: 70vw;
    color: #686b73;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  &-table {
    width: 100%;
    border-collapse: collapse;

    &-head,
    &-row {
      background: #e7f4ff;
      color: #686b73;
      font-weight: bold;
      text-align: center;
      display: flex;

      & > div {
        padding: 10px;

        &:first-child {
          flex: 3;
        }

        &:nth-child(2) {
          flex: 2;
        }

        &:nth-child(3) {
          flex: 2;
        }

        &:last-child {
          flex: 1;
        }
      }
    }

    &-row {
      background-color: #fff;

      & > div {
        padding: 5px;
      }
    }
  }
}

.deleted {
  text-decoration: line-through; /* 实现删除线效果 */
  color: #91919183; /* 颜色 */
}

.audit {
  &-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    min-height: 25vw;

    &-left {
      display: flex;
      flex-direction: column;
    }

    &-right {
      display: flex;
      flex-direction: column;
      justify-content: end;
      font-size: 0.35rem;
      position: relative;
      width: 40vw;
      text-align: center;
      background: url('https://img.dev.zhijiangfilm.com/i/2024/10/12/6709d10ac456c.png') no-repeat center top;
      background-size: 50%;

      &-status-0 {
        background: none;
      }

      &-status-1 {
        background: none;
      }
    }
  }
}
</style>
